.bold-details {
  font-weight: bold;
  line-height: 0px;
  color:#04295D;
  margin-top: 20px;
  margin-bottom: 10px;
}

.title-details {
  font-weight: bold;
  font-size: 1.5rem;
  color:#04295D;
}


.ecarter-prix {
  float: right;
  font-size: 1em;
  color: black;
}
