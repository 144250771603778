.Gestiondesressourceshumaines-header h1 {
  text-align: center;
  font-size: 3.5rem;
  padding-bottom: 1em;
  border-bottom: 1px solid gray;
  font-weight: bold;
  color: #04295d;
}

.Gestiondesressourceshumaines {
  display: flex;
  flex-flow: row wrap;
  margin: 6em;
  margin-top: 5em;
  justify-content: center;
}

.rh_button {
  padding: 1.5em;
  /* box-shadow: 1px 5px 6px 2px rgb(0 0 255 / 20%); */
  margin: 1em;
  font-weight: bold;
  border-radius: 1em;
  background-color: #04295d;
  color: white;
  width: 220px;
  height: 170px;
}

.rh_button:hover {
  background-color: #2fbfff;
}

@media screen and (max-width: 800px) {
  .Gestiondesressourceshumaines {
    display: grid;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 1em;
    margin: 0em;
  }
}
@media screen and (max-width: 1050px) {
  .Gestiondesressourceshumaines {
    padding-top: 2em;
    margin: -2em;
  }
}

.facture_button:hover {
  background-color: #2fbfff;

  border: solid 4px #3ebde6;
}
