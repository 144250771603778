.product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5rem 0;
}

.product-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product img {
  max-width: 20%;
  object-fit: contain;
  justify-self: center;
  align-self: center;
}

.product--info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
}

.product--info p {
  margin: 0;
}

.product--info-title {
  font-size: 1.5rem;
  margin-bottom: 0rem !important;
  justify-self: flex-start !important;
  font-weight: bold;
}

.product-list p {
  margin-bottom: 0;
}

.menu__details-title {
  font-weight: bold;
  margin: 0 0 0 2rem;
}

.menu__details {
  margin: 0 0 0 2rem;
  width: 100%;
}

.product--info-prix {
  font-size: 1.5rem;
  justify-self: flex-end;
  align-self: center;
  margin-bottom: 0px !important;
}

.product--quantite {
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  color: white;
  background-color: #B1A26B;
  align-items: center;
  font-weight: bold;
  /*-moz-box-shadow-bottom: 2px 2px 2px grey;
  -webkit-box-shadow-bottom: 2px 2px 2px grey;
  box-shadow: 2px 2px 2px grey;*/
  padding: 0.4rem 0;
  margin: 0 1rem;
}
.product--quantite button {
  margin: 0 10px;
}

#mode_livraison{
  margin-bottom: 50px !important;
}

.separation_ligne{
  border-bottom: solid 1px !important;
  border-color: lightgray !important;
}

.product-icon{
  color: white !important;
}

.delete__product {
  justify-self: center;
  align-self: center;
  margin-top: 5px;
  font-size: 60px !important;
  color: #B1A26B !important;
  cursor: pointer !important;
}

.ecarter-prix {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 600px) {
  .product--info-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {
  .product {
    grid-template-columns: 1fr;
    padding: 1rem;
    grid-gap: 1rem;
  }
  .product-list {
    align-items: center;
  }
}
