#traiteur {
    background-color: #060505;
    padding: 70px;
    position: relative;
    z-index: 8;
    height: 100vh;
    margin-top: -131px;
}

.Div_Img_Traiteur_Accueil{
    color: #FFE897;
    height: 100%;
    background: url("../../../../images/img_traiteur.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    padding-top: 65vh;
}

.Div_Container_Accueil_Traiteur {
    padding-top: 150px;
}

.Div_Acceuil_Traiteur{
    width: 500px;
    margin:0 auto;
}

#Text_Div_Acceuil_Traiteur{
    font-family: Monoton;
    word-spacing: 10px;
    text-align: center;
    color: #B1A26C;
    font-size: 36px;
}

#Sous_Text_Div_Acceuil_Traiteur{

    font-family: Poppins;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: white;
    margin-top: -21px;
}

.Btn_Commander_Acceuil_Traiteur{
    font-size: var(--font-size-button);
    color: #B1A26C;
    border: 2px solid #B1A26C;
    text-align: center;
    margin-top: 1em;
    font-family: Poppins;
    padding: 5px 20px;
}

.Btn_Commander_Acceuil_Traiteur:hover{
    background-color: #B1A26C;
}

@media only screen and (max-width: 1200px){
    .Div_Img_Traiteur_Accueil{
        width: 100%;
        padding-top: 20em;
        padding-bottom: 20em;
    }

    .Div_Acceuil_Traiteur{
        padding: 30px 20px 0px 20px;
    }

}

/* style width, height */
@media screen and (max-width:960px), (max-height: 700px) {

    #Sous_Text_Div_Acceuil_Traiteur{
        font-size: var(--font-size-mobile);
        line-height: 24px;
       
    }

    #Text_Div_Acceuil_Traiteur{
        font-size: var(--font-size-titre-mobile);
    }

    .Btn_Commander_Acceuil_Traiteur{
        font-size: var(--font-size-button-mobile);
    }
}

/* style width */
@media screen and (max-width:960px) {
    #traiteur {
        height: max-content;
        padding: 30px;
        margin-top: -95px;
    }

    .Div_Container_Accueil_Traiteur {
        padding-top: 50px;
    }
}

/* style height */
@media screen and (max-height: 700px ) {
    .Div_Container_Accueil_Traiteur {
        padding-top: 100px;
    }

    .Div_Acceuil_Traiteur {
        padding-top: 6vh;
    }
}

@media screen and (max-height: 550px ) {
    .Div_Container_Accueil_Traiteur {
        padding-top: 100px;
    }

    .Div_Acceuil_Traiteur {
        padding-top: 4vh;
    }

    .Div_Img_Traiteur_Accueil{
        padding-top: 30vh;
        padding-bottom: 25vh;
    }
}

/* style width and height */
@media only screen and (max-width:960px) and (max-height: 700px) {
    .Div_Container_Accueil_Traiteur {
        padding-top: 9vh;
    }
}

@media only screen and (max-width:960px) and (max-height: 550px) {
    .Div_Container_Accueil_Traiteur {
        padding-top: 80px;
    }
}