.alertsSupp{
    position: absolute;
    top:0;
    left: 0;
    min-height: 100%;
    width: 98.5vw;
    background-color: rgb(0,0,0,0.5);
    overflow: hidden;
    z-index: 200;
}

.alertsSupp .content{
    height: 35%;
    width: 35%;
    margin: auto;
    margin-top: 20%;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
}

.alertsSupp h4{
    text-align: center;
    margin-top: 2%;
}

.alertsSupp h5{
    text-align: center;
}

.centeredButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 1%;
}
.yes_button {
    position: relative;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #21bd36;
    font-weight: bold;
    color: white;
    width: 5em;
    height: 3em;
    padding: 0;
    margin: 0;
  }

.no_button {
    position: relative;
    margin: auto;
    border: 1px solid white;
    border-radius: 0.5em;
    background-color: #ec412a;
    font-weight: bold;
    color: white;
    width: 5em;
    height: 3em;
    padding: 0;
    margin: 0;
    margin-left: 1%;
  }