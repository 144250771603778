#description {
    position: relative;
    z-index: 10;
}

.Text_DivBas_Acceuil{

    line-height: 36px;
    padding-top: 5px;
    font-size: 28px;
    padding-bottom: 3%;
    
}

.DivBas_Acceuil{

    background-color: #060505;
    color: white;
    
}
.petitlogo {
    padding-bottom: 2%;
}


#Text_PaulBocuse{
    font-size: 36px;
    font-family: Monoton;
    margin-top: 2%;
    float: right;
    color: var(--secondary-color);
    letter-spacing: 0.2em;

}

.Grande_Div{
    justify-content: center;
    align-self: center;
    width: 80%;
    margin: 0 auto;
}


.Text_DivBas_Acceuil strong{

    font-style: italic;
    font-size: 1.5em;
    font-weight: bold;
    
}

#Text_Principal{
    margin-bottom: 4%;
}


@media screen and (max-width:1200px), (max-height: 700px) {

    #Text_Principal, #Text_PaulBocuse{

        font-size: 20px;
    }

    .Text_DivBas_Acceuil strong{

        font-size: 25px;
        
    }


}
    


@media screen and (max-width:960px) {

    .Text_DivBas_Acceuil{

        line-height: 24px;   
        
    }

    #Text_Principal, #Text_PaulBocuse{

        font-size: 15px;
    }

    .Text_DivBas_Acceuil strong{

        font-size: 20px;
        
    }

    .Grande_Div{
        
        width: 100%;
        
    }
    
}