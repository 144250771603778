/* Background for contact */
#contact-form {
    background-image: url("../../../images/fondGonak.png");
    background-size: cover;
    margin-top: 120px;
}

@media screen and (max-width: 960px) {
    /* Background for contact */
    #contact-form {
        margin-top: 77px;
    }
}
