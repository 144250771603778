#homeMenu {
    background-image: url("../../../../images/fondGonak.png");
    background-size: cover; 
    height: 100vh;
    margin-top: -135px;
    position: relative;
    z-index: 9;
}

.Div_Acceuil_Menu{
    padding-top: 170px;
}

#Text_Div_Acceuil_Menu{
    word-spacing: 10px;
    font-family: Monoton;
    text-align: center;
    color: var(--secondary-color);
    font-size: var(--font-size-titre);
}
.containerMenu{
    width: 80%;
    margin: 0 auto;
}


.Card_Acceuil{
    color: #FFFFFF;
    padding: 0;
    margin: 30px auto;
    justify-content: space-around;
    align-items: center;
    background-color: var(--secondary-color-light);
    border: solid 0px var(--secondary-color-light);
}

.Card_Img1{
    background-size:cover ;
    margin: auto;
    height: 50vh;
}

.Card_Accueil_Text{
    padding-top: 40vh;
    font-size: 19px;
    font-weight: bold;
    padding-left: 10px;
}

/* size for arrow */
.Card_Accueil_Text .MuiSvgIcon-root {
    font-size: 19px;
}

.Arrow_Card_Accueil{
    margin-left: 10px;
    margin-top: -27px;
}

.Btn_Carte_Menu{
        font-size: var(--font-size-button);
        color: var(--secondary-color);
        background: #060505;
        justify-content:center;    
        border: 2px solid var(--secondary-color);
        align-items: center;
        margin: 0 auto;
        display: flex;
        font-family: Poppins;
        padding: 5px 20px;
}

.Btn_Carte_Menu:hover{
    background-color: #FFFFFF;
}

#homeMenu .carousel-indicators{
    margin-bottom: 0px !important;
}

@media screen and (max-width: 900px){
    #Text_Div_Acceuil_Menu{
        font-size: var(--font-size-titre-mobile);
    }
    
    .Card_Accueil_Text{
        font-size: 1em;
        padding-left: 1em;
    }

    /* style for arrow in mobile */
    .Arrow_Card_Accueil{
        margin-left: 14px;
    }

    .Btn_Carte_Menu{
        font-size: var(--font-size-button-mobile);
        margin-bottom: 25px;
    }

    /* distance between image and slide */
    .Card_Img1{
        margin-top: -30px;
    }

    #homeMenu {
        height: max-content;
    }
}



/* style height */
@media screen and (max-height: 700px) {
    #Text_Div_Acceuil_Menu{
        font-size: var(--font-size-titre-mobile);
    }

    .Btn_Carte_Menu{
        font-size: var(--font-size-button-mobile);
    }

    .Card_Accueil_Text{
        padding-top: 33vh;
        font-size: 1em;
    }

    .Div_Acceuil_Menu {
        padding-top: 161px;
    }

    .containerMenu {
        margin-top: -16px;
    }

    .Card_Img1{
        height: 45vh;
    }
}

@media screen and (max-height: 550px) {
    .Card_Accueil_Text{
        padding-top: 26vh;
    }

    .Div_Acceuil_Menu {
        padding-top: 150px;
    }

    .containerMenu {
        margin-top: -20px;
    }

    .Card_Img1{
        height: 40vh;
    }
}

@media screen and (min-height: 800px) {
    .Btn_Carte_Menu{
        margin-top: 5vh;    
    }
}

/* style height and width */
@media only screen and (max-width: 900px) and (max-height: 700px) {
    .Card_Accueil_Text{
        padding-top: 28vh;
    }
}

@media only screen and (max-width: 900px) and (max-height: 550px) {
    .Card_Accueil_Text{
        padding-top: 19vh;
    }
}

@media only screen and (max-width: 900px) and (min-height: 700px) {
    .Card_Accueil_Text{
        padding-top: 36vh;
    }
}