.Fond_Acceuil_Gonak{
        color: #FFE897;
        height: 100vh;
        width: 100%;
        background: url("../../../../images/Fond_Acceuil_Gonak.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        z-index: 1;
        padding-top: 30vh;
        padding-left: 8vh;

    }

    .Text_Fond_Acceuil{
        color: #B1A26C;
        font-size: 64px;
        font-family: Monoton;
        text-align: center;
        
    }
    .Text_Fond_Acceuil p{
        font-size: var(--font-size);
        font-family: Monoton;
        text-align: start;
        color: var(--secondary-color)
        
    }
    .description_fond_acceuil {
        font-family: Poppins;
        font-weight: 700;
        font-style: italic;
        line-height: 54px;
        font-size: 36px;
        color: white;
    }

    .Btn_Reserver_Acceuil{
        font-size: 24px;
        font-weight: normal;
        font-style: normal;
        color: #FFE897;
        background: #060505;
        border: 1px solid #FFE897;
        text-align: center;
        margin-top: 2%;
        font-family: Poppins;
        padding: 5px 20px;
    }
    .coloredText {
        color: var(--secondary-color-light);
    }

    .Btn_Reserver_Acceuil:hover{
        background-color: #FFE897;
    }

    @media screen and (max-width: 960px) ,(max-height: 690px) {
        .Fond_Acceuil_Gonak{
            padding-top: 20vh !important;
            padding-left: 4vh !important;

        }
        .Text_Fond_Acceuil{
            font-size: var(--font-size-titre);
        }
        .description_fond_acceuil {
            font-size: var(--font-size-button-mobile);

        }


        .Btn_Reserver_Acceuil{
            font-size: var(--font-size-button-mobile);
            color: #FFE897;
            Width: 12em;
            height: 2em;   
            border: 2px solid #FFE897;
            text-align: center;
            margin-top: 2%;
        }

        .Fond_Acceuil_Gonak{
            padding-top: 45vh;
        }
    
    }

