.myModal__backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 57, 57, 0.15);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myModal__modal {
  max-height: 98vh;
  overflow-y: auto;
  max-width: 500px;
  width: 95%;
  padding: 1rem;
  background: var(--third-color);
  border-radius: 10px;
  position: relative;
}

.myModal__modal__close {
  position: absolute;
  top: 2px;
  right: 10px;
  padding-top: 1%;
}
.myModal__modal__close i {
  font-size: 20px;
  pointer-events: none;
}

.myModal__header {
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  padding: 10% 0;
  margin-bottom: 10px;
  border-bottom: solid 0px black;
}

.myModal__header h1 {
  text-align: left;
  color: rgb(100, 100, 100);
  font-size: 2.2rem;
}
.myModal__header h2 {
  text-align: left;
  color: rgb(100, 100, 100);
  font-size: 1.5rem;
}
.myModal__header p {
  margin: 0;
}

.myModal__header img {
  max-width: 88%;
  max-height: 18em;
  object-fit: contain;
  margin-top: 20px;
}

.myModal__body,
.myModal__footer {
  padding: 10px 0;
}

.myModal__body__heading {
  font-size: 1.3rem;
  font-weight: bold;
}

.myModal__body {
  border-bottom: 1px solid rgba(104, 96, 65, 0.3);
}

.myModal__footer {
  border: none;
  display: flex;
  justify-content: center;
}



@media (max-width: 500px) {
  

  .myModal__header h1 {
    font-size: 1.8rem;
  }
  .myModal__header h2 {
    font-size: 1.3rem;
  }
  .myModal__modal {
    margin-top: -20px;
    height: 90%;
  }
}
