.btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 ;
}

/* style for acceuil */
#acceuil {
    margin-top: 120px;
}

/* Mobile version */
@media screen and (max-width: 960px) {
    /* style for acceuil */
    #acceuil {
        margin-top: 77px;
    }
}